<template>
    <tr
      v-for="item in data"
      :key="item.id"
      class="border-b hover:bg-orange-100 bg-white text-grey"
    >
      <td class="p-1 px-5 hover:underline cursor-pointer">
        <p>{{ item.location }}</p>
      </td>
      <td class="p-1 px-5">
        <p v-if="item.start_date">{{ item.start_date }}</p>
      </td>
      <td class="p-1 px-5">
        <p>{{ item.end_date }}</p>
      </td>
      <td class="p-1 px-5">
        {{ item.start_time }}
      </td>
      <td class="p-1 px-5">
        {{item.end_time}}
      </td>
      <td class="p-1 px-5">
        {{item.rate}}
      </td>
      <td class="p-1 px-5">
        <div v-html="item.invoice"></div>
      </td>
      <td class="p-3 px-5">
        {{item.esm_status}}
      </td>
    </tr>
  </template>
  
  <script>
  export default {
    created(){
        console.log("DATAs:", this.$props.data)
    },
    props: {
      data: {
        type: Object,
        required: true,
      },
      link: {
        type: String,
        required: true,
      },
      myParameter: {
        type: String,
      },
    },
  };
  </script>
  